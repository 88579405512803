import contact from './lib/contact'
import { toggleBelowFold, scrollSpy, smoothScroll } from './lib/scroll'


(function () {
  'use strict';

  /* global $ */

  function hasSecondaryNav() {
    var hasSecondary = $('.active .level-2, .current .level-2').length > 0;
    if (hasSecondary) {
      $('body').addClass('secondary-nav');
    }
  }

  function showMobileNav() {
    $('#navbar').on('shown.bs.collapse', function () {
      $('body').addClass('show-mobile-nav');
    });
    $('#navbar').on('hide.bs.collapse', function () {
      $('body').removeClass('show-mobile-nav');
    });
  }

  function toggleMoreArrow() {
    $('[data-toggle-arrow]').on('click', function(evt) {
      $(this).toggleClass('arrow-up');
      $(this).toggleClass('arrow-down');
    });
  }

  function lightbox() {
    var plugins = $.fn.imageLightbox.plugins;
    var groups = {};

    $('a[data-imagelightbox]').each(function() {
      var group = $(this).attr('data-imagelightbox') || 'default';
      var selector = 'a[data-imagelightbox="' + group + '"]';
      if (groups[group]) { return; }
      var lightbox = $(selector).imageLightbox({
        onStart: function() {
          plugins.overlayOn();
          plugins.closeButtonOn(lightbox);
          plugins.navigationOn(lightbox, selector);
        },
  			onEnd: function() {
          plugins.overlayOff();
          plugins.closeButtonOff();
          plugins.navigationOff();
        },
        onLoadEnd: function() {
          plugins.navigationUpdate(selector);
        }
      });
      groups[group] = true;
    });
  }

  function slick() {
    $(".blog .blog-feed__articles").slick({
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 3,
      responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2
            }
          },
          {
            breakpoint: 480,
            settings: "unslick"
          }
          // You can unslick at a given breakpoint now by adding:
          // settings: "unslick"
          // instead of a settings object
        ]
    });
  }

  $(document).ready(function () {
    contact('#contact-form');
    hasSecondaryNav();
    showMobileNav();
    toggleMoreArrow();
    lightbox();
    slick();
  });
})();

$('.content-slider .neos-contentcollection').slick({
    dots: true,
    arrows: true,
    infinite: false,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: false,
});
